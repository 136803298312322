<template>
  <div v-if="!pending && productItem" class="ProductPage desk:layout-container">
    <div v-if="productContent && productContent.topBlocks">
      <SpaceHandler
        v-for="childBlok in productContent.topBlocks"
        :key="childBlok._uid"
        :blok="childBlok"
      >
        <StoryblokComponent
          :blok="childBlok"
        />
      </SpaceHandler>
    </div>

    <!-- * * * * PRODUCT -->
    <ProductBreadcrumbs :product-item="productItem" />

    <ProductExtendedLayout
      v-if="productItem.extendedLayout"
      :product-item="productItem"
      :active-variant="activeVariant"
      :current-image-list="currentImageList"
    />

    <ProductDefaultLayout
      v-else
      :product-item="productItem"
      :active-variant="activeVariant"
      :current-image-list="currentImageList"
      @update-active-variant="(variant) => changeVariant(variant)"
    />
    <!-- * * * * /PRODUCT -->

    <!-- * * * * related products -->
    <client-only>
      <RelatedProductsList
        v-for="(list, key) in relatedProductsLists"
        :key="'products' + key"
        :item-list="list.products"
        :title="list.title"
      />
    </client-only>

    <div class="py-24 flex-1">
      <!-- * * * * * * bottom content -->
      <div v-if="productContent && productContent.bottomBlocks">
        <SpaceHandler
          v-for="childBlok in productContent.bottomBlocks"
          :key="childBlok._uid"
          :blok="childBlok"
        >
          <StoryblokComponent
            :blok="childBlok"
          />
        </SpaceHandler>
      </div>
    </div>
  </div>
  <div v-else class="pt-56 text-center">
    <LoadingIcon />
  </div>
</template>

<script lang="ts" setup>
import Product from '~/models/product';
import { useUiStore } from '~/store/ui';
import { useProductStore } from '~/store/product';
import { useGlobalContentStore } from '~/store/globalContent';
import { Head } from '@unhead/schema';
import { StoryBlokProductPageContent } from '~/constants/types/storyblok';
import SpaceHandler from '~/components/body/SpaceHandler.vue';
import ProductBreadcrumbs from '~/components/product-page/ProductBreadcrumbs.vue';
import RelatedProductsList from '~/components/product-page/RelatedProductsList.vue';
import ProductExtendedLayout from '~/components/product-page/ProductExtendedLayout.vue';
import ProductDefaultLayout from '~/components/product-page/ProductDefaultLayout.vue';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import { NorceMediaSetCodes, NorceRelatedProductTypeCodes } from '~/constants/norceCodes';
import domainMapper from '~/util/domain-mapper';
import { createDataLayerItem, sendDataLayer } from '~/composeables/useTracking';
import { ProductVariantModel } from '~/models/productVariant';

const uiStore = useUiStore();
const productStore = useProductStore();
const globalContent = useGlobalContentStore();
const route = useRoute();
const splitRoute = route.path.split('/');
const productUrl = ref(splitRoute[splitRoute.length - 1]);
const currentEnvironment = globalContent.getEnvironment;
const nuxtApp = useNuxtApp();
const { $t } = useNuxtApp();

const storyblokApi = useStoryblokApi();
const runtimeConfig = useRuntimeConfig();
const { getProductByUrl } = useAlgoliaFetch();
const pending = ref(true);

// * * * * * * * * * Load and initialize
const { data } = await useAsyncData('sbload', async() => {
  const sbReq = storyblokApi.get('cdn/stories', {
    starts_with: `${globalContent.getStoryblokBasePath}/${globalContent.getProductBasePath}`,
    filter_query: {
      'product.product.url': {
        in: productUrl.value,
      },
    },
    version: runtimeConfig.public.storyblokStoriesVersion as 'draft' | 'published' | undefined,
  });

  const res = await getProductByUrl(productUrl.value);

  return await Promise.all([sbReq, res]);
});

const responses = data.value;
const sbData = responses && responses[0].data?.stories[0];
const productData = ref(responses && responses[1]);
const relatedProductsLists = ref([] as any);
const productContent = ref<StoryBlokProductPageContent | undefined>(sbData?.content);

if (!productData.value || productData.value.hits.length === 0) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
}

const productItem = Product.create(productData.value.hits);
const currentImageList = ref<NorceMediaSetCodes>(NorceMediaSetCodes.DefaultImages);

const activeVariant = ref(productItem.variantByUrl(productUrl.value));

const sendProductImpression = async() => {
  if (currentEnvironment !== 'local' && activeVariant.value?.partNo) {
    await productStore.sendProductImpression(activeVariant.value?.partNo);
  }
};

const changeVariant = (variant: ProductVariantModel) => {
  window.history.pushState({ prevVariantUrl: variant.url },'', window.location.href.replace(productUrl.value, variant.url));
  activeVariant.value = variant;
  productUrl.value = variant.url;
  sendProductImpression();
};
onMounted(()=> {
  sendProductImpression();

  window.onpopstate = (e) => {
    if (e.state?.prevVariantUrl) {
      activeVariant.value = productItem.variantByUrl(e.state.prevVariantUrl);
      sendProductImpression();
    }
  };
});

onBeforeUnmount(()=> {
  window.onpopstate  = () => {};
});

pending.value = false;
// * * * * * * * * * Finished initialize

// * * * * recently visited
const recentlyVisited = useCookie<{ url: string, image: string, partNo: string }[]>(
  'recentlyVisited',
  {
    default: () => [],
    watch: 'shallow',
  }
);

if (productItem?.partNo) {
  recentlyVisited.value = recentlyVisited.value?.filter(visitedProduct => visitedProduct.partNo !== productItem.partNo);
  recentlyVisited.value?.unshift({ url: productItem.url, image: productItem.mainImage, partNo: productItem.partNo });
}

// - - - - - - -  related products
const loadRelations = productItem.extendedLayout
  ? [NorceRelatedProductTypeCodes.Related]
  : [NorceRelatedProductTypeCodes.Related, NorceRelatedProductTypeCodes.Upsell];

loadRelations.map(async(relation: NorceRelatedProductTypeCodes) => {
  const partNos = productItem.relatedProductsByType(relation);
  if (partNos.length > 0) {
    relatedProductsLists.value.push({
      title: productItem.extendedLayout ? $t('productPage.relatedProducts.addOns') : $t('productPage.relatedProducts.' + relation.replace('-', '')),
      products: await productStore.getRelatedProducts(partNos),
    });
  }
});

if (productItem.extendedLayout) {
  const products = await productStore.getProductBrandRelatedProducts(productItem);
  if (products.length > 0) {
    relatedProductsLists.value.push({
      title: $t('productPage.relatedProducts.fromBrand', { brand : productItem.brand.name }),
      products: products,
    });
  }
}

// - - - - - - -  SEO Stuff
const head = {} as Head;
const seoMeta = {} as { [key: string]: string | null };
let pageTitle = productItem.name;
head.titleTemplate = ''; // removes auto ' | siteName'

head.title = pageTitle;
seoMeta.title = pageTitle;
seoMeta.ogTitle = pageTitle;
seoMeta.ogImage = productItem.mainImage;
seoMeta.twitterCard = 'summary_large_image';

seoMeta.description = productItem.metaDescription;
seoMeta.ogDescription = productItem.metaDescription;

const jsonLd = {
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: productItem.name,
  image: productItem.imageList,
  description: productItem.metaDescription,
  sku: productItem.partNo,
  mpn: productItem.partNo,
  brand: {
    '@type': 'Brand',
    name: productItem.brand.name,
  },
  offers: {
    '@type': 'Offer',
    url: productItem.url,
    priceCurrency: globalContent.getCurrency,
    itemCondition: 'https://schema.org/NewCondition',
    availability: activeVariant?.value?.canAddToCart ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
  },
} as any;

if (productItem.priceInfo.priceValidUntil) {
  jsonLd.offers.priceValidUntil = productItem.priceInfo.priceValidUntil;
}

useHead(head);
useJsonld(jsonLd);
useServerSeoMeta(seoMeta);

// datalayer
let hostname = '';
if (process.server) {
  hostname = nuxtApp?.ssrContext?.event?.node?.req?.headers?.host ?? '';
} else {
  hostname = window.location.host;
}

let foundDomain = domainMapper.find((d: { domain: any; }) => d.domain === hostname);
const activeVariantUrl = activeVariant.value?.url || '';
if (activeVariantUrl) {
  useHead(() => ({
    link: [
      {
        rel: 'canonical',
        href: 'https://' + foundDomain?.domain + route.path.replace(activeVariantUrl, productItem.url),
      },
    ],
  }));
}

onMounted(async()=> {
  sendDataLayer(
    'view_item',
    [ createDataLayerItem(productItem) ]
  );
});

</script>
