<template>
  <div class="relative z-10 my-12 type-xs desk:type-sm text-dark flex px-12 desk:px-0 sm:px-0 md:mb-0">
    <div
      class="flex items-center hover:underline hover:text-darkest transition-all"
    >
      <nuxt-link
        :href="$manualUrl(brandlistUrl)"
        class="hoverdistance"
      >
        {{ globalContent.brandlistPage?.story?.name }}
      </nuxt-link>
    </div>
    <span class="px-8 type-xs desk:type-sm">/</span>
    <div
      v-if="globalContent.getBrandLink(productItem.brand.code)"
      class="flex items-center hover:underline hover:text-darkest transition-all"
    >
      <nuxt-link
        :href="globalContent.getBrandLink(productItem.brand.code) || ''"
        class="hoverdistance"
      >
        {{ productItem.brand.name }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import { useGlobalContentStore } from '~/store/globalContent';

const globalContent = useGlobalContentStore();

defineProps<{
  productItem: ProductModel,
}>();

const brandlistUrl = globalContent.brandlistPage?.story?.full_slug?.replace(globalContent.getStoryblokBasePath + '/', '') || '/';

</script>

<style scoped lang="postcss">

</style>
