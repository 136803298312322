<template>
  <div class="FullScreenGallery fixed inset-0 z-modals bg-lightest">
    <div v-if="isMobile" class="absolute top-32 left-24 h-24 w-32 bg-lightest flex justify-center items-center z-10 type-xs-medium">
      {{ `${activeIndex + 1}/${images.length}` }}
    </div>
    <button class="absolute top-32 right-32 bg-lightest flex items-center justify-center h-40 w-40 z-20" @click="$emit('close')">
      <img src="/icons/close.svg" class="h-24 w-24">
    </button>
    <div v-if="isMobile" class="absolute top-[50%] left-24 z-10">
      <div
        class="swiper-button-prev full-screen-button-prev"
      />
    </div>
    <div v-if="isMobile" class="absolute top-[50%] right-24 z-10">
      <div
        class="swiper-button-next full-screen-button-next"
      />
    </div>
    <div v-if="isMobile">
      <client-only>
        <Swiper
          class="swiper-cover flex w-full h-full"
          :space-between="12"
          slides-per-view="auto"
          :no-swiping="true"
          :modules="[SwiperNavigation]"
          :initial-slide="startIndex"
          :navigation="{
            enabled: true,
            nextEl: `.swiper-button-next.full-screen-button-next`,
            prevEl: `.swiper-button-prev.full-screen-button-prev`,
          }"
          @slide-change="onSlideChange"
        >
          <SwiperSlide
            v-for="image in images"
            :key="'full-gallery-image-' + image.file"
            class="h-full w-full"
          >
            <nuxt-img
              :src="image.file"
              preset="full"
              provider="norce"
              loading="lazy"
              sizes="sm:100vw desk:1260px lg:100vw"
              class="w-full h-full object-cover"
            />
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
    <div
      v-else
      ref="imageContainer"
      class="h-full w-full relative overflow-auto"
    >
      <div
        class="fixed inset-0 flex justify-center z-10"
        @click="onZoom"
      >
        <nuxt-img
          v-if="images[activeIndex].type === 'image'"
          :src="images[activeIndex].file"
          preset="full"
          provider="norce"
          loading="lazy"
          sizes="sm:1500px"
          class="h-full object-contain transition-all duration-500 cursor-zoom-in"
        />
        <div
          v-else
          class="h-full"
        >
          <video
            autoplay
            muted
            loop
            playsinline
            class="w-full h-full object-cover"
          >
            <source :src="images[activeIndex].file" type="video/mp4">
          </video>
        </div>
      </div>
      <div class="flex flex-col z-20 fixed left-24 top-24 bottom-24">
        <div
          v-for="(image, index) in images"
          :key="'full-gallery-image-' + image.file"
          class="w-[75px] mb-12 mr-12 overflow-hidden cursor-pointer aspect-square"
          :class="{
            'border border-darkest': index === activeIndex
          }"
          @click="changeImage(index)"
        >
          <nuxt-img
            v-if="image.type === 'image'"
            :src="image.file"
            preset="standard"
            provider="norce"
            loading="lazy"
            sizes="sm:75px"
            class="w-full h-full object-cover"
          />
          <div v-else class="bg-light aspect-product flex items-center justify-center">
            <img src="/fontawesome/darkest/solid/circle-play.svg" class="w-24 h-24">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';

import { ref } from 'vue';
import { NorceMediaSetCodes } from '~/constants/norceCodes';
import { ProductImageAndVideo, ProductModel } from '~/models/product';

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

const props = defineProps<{
  images: ProductImageAndVideo[];
  startIndex: number;
  currentImageList: NorceMediaSetCodes;
  productItem: ProductModel;
}>();

defineEmits(['close']);

const activeIndex = ref(props.startIndex);
const imageContainer = ref<HTMLElement>();

function onZoom(e: MouseEvent) {
  const target = e.target as HTMLElement;
  const img = target.closest('img');

  if (!img) return;

  const { width, left } = img.getBoundingClientRect();
  const { clientY } = e;

  img.style.transformOrigin = '0% 0% 0px';

  img.style.transform = img.style.transform
    ? ''
    : `scale(${window.innerWidth / width}) translate(-${left / 2}px, -${clientY / 2}px)`;

  img.style.cursor = img.style.transform
    ? 'zoom-out'
    : 'zoom-in';
}

const changeImage = (index: number) => {
  activeIndex.value = index;
};

const onSlideChange = (input: any) => {
  activeIndex.value = input.activeIndex;
};

</script>
