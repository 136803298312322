<template>
  <div
    v-if="!loading && articles.length"
    :class="{
      'border-b border-light': !open
    }"
    class="ProductArticles"
  >
    <!-- head -->
    <div
      class="type-headline-sm select-none cursor-pointer flex justify-between md:py-24 py-16"
      @click="open = !open"
    >
      {{ $t('productPage.info.guidesAndInspiration') }}
      <img
        v-if="!open"
        src="/icons/plus.svg"
        class="w-20 h-20"
      >
      <img
        v-else
        src="/icons/minus.svg"
        class="w-20 h-20"
      >
    </div>
    <!-- content -->
    <div
      class="overflow-hidden transition-all"
      :class="{
        'max-h-0 opacity-0': !open,
        'max-h-[3000px] opacity-100 md:mb-24 mb-16': open
      }"
    >
      <div class="grid grid-cols-2 gap-12">
        <nuxt-link
          v-for="article in articles"
          :key="article.uuid"
          :to="`/${article.full_slug}`"
        >
          <div class="aspect-landscape mb-16">
            <nuxt-img
              :src="article.content.summaryImage.filename"
              preset="standard"
              loading="lazy"
              sizes="mob375:50vw mob390:50vw mob414:50vw desk:273px"
              class="h-full w-full object-cover"
            />
          </div>
          <div
            v-if="article.content.summaryCategories"
            class="mb-12 type-headline-xs"
          >
            <span class="underline">{{ article.content.summaryCategories }}</span>
          </div>
          <div class="type-headline-lg">
            {{ article.content.summaryTitle }}
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductModel } from '~/models/product';
import { useGlobalContentStore } from '~/store/globalContent';
import { StoryBlokArticlePage } from '~/constants/types/storyblok';
const storyblokApi = useStoryblokApi();
const globalContent = useGlobalContentStore();

const props = defineProps<{
  productItem: ProductModel;
}>();

const open = ref(false);
const loading = ref(true);

onMounted(()=> {
  loadArticles();
});

const articles = ref<StoryBlokArticlePage[]>([]);

const loadArticles = async() => {
  const filters = props.productItem.categories.map((m) => {
    return { 'category.category.id': { in: m.code } };
  }) as any[];
  if (props.productItem.brand?.code) {
    filters.push({ 'brand.brand.id': { in: props.productItem.brand.code } });
  }

  const { data } = await storyblokApi.get('cdn/stories/', {
    version: useRuntimeConfig().public.storyblokStoriesVersion as 'draft' | 'published',
    starts_with: globalContent.articlesFolder,
    content_type: 'ArticlePage',
    excluding_fields: 'body',
    per_page: 2,
    filter_query: {
      __or: filters,
    },
  });
  if (data) {
    articles.value = data.stories;
    loading.value = false;
  }
};

</script>
