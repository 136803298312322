<template>
  <div class="ProductExtendedLayout deskStd:flex desk:mb-80 desk:pt-16">
    <!-- * * * main image -->
    <div class="deskStd:basis-1/2 shrink-0 desk:flex">
      <div class="hidden desk:block basis-[98px] shrink-0 mr-12">
        <div id="ProductImageThumbnails" class="flex justify-end" />
      </div>
      <div class="desk:basis-[538px] desk:w-[538px] shrink-0">
        <ProductImage
          :product-item="productItem"
          :current-image-list="currentImageList"
          :active-variant="activeVariant"
        />
      </div>
    </div>
    <!-- content -->
    <div
      class="desk:flex-1 deskStd:basis-1/2 shrink-0
             pt-24 desk:px-0 desk:pt-24 deskStd:pt-0 deskStd:pl-60"
    >
      <!-- * * * * * * name & category -->
      <div class="mobOnly:px-12">
        <NameAndCategory
          :product-item="productItem"
          :active-variant="activeVariant"
          :show-part-no="false"
        />
      </div>

      <!-- * * * * * * variant selector -->
      <div class="font-medium uppercase mobOnly:px-12">
        {{ productItem.variantsTitle }}
        <div class="basis-1/2 flex items-center my-20 pb-12 shadow-bottomLine">
          <img
            src="/icons/search.svg"
            class="w-24 h-24 mr-12"
            alt="Sök"
          >
          <input
            v-model="productVariantSearchInput"
            type="text"
            :placeholder="$t('productPage.search.placeholder')"
            class="w-full placeholder-opacity-100 text-14"
          >
        </div>
      </div>

      <div
        v-for="(parametric, index) in productItem.allVariantDefiningParametrics"
        :key="`variant-parametric-${parametric.code}-${index}`"
      >
        <div v-if="!uiStore.isMobile" class="mb-12">
          <button
            class="btn btn--secondary inline-block mr-8 mb-8"
            selectedVariantGroup
            :class="[{
              'selected': !selectedVariantGroup,
            }]"
            @click="selectedVariantGroup = ''"
          >
            {{ $t('productPage.extended.showAll') }}
          </button>
          <div
            v-for="(value, index2) in parametric.values"
            :key="`vpvDesk-${value.code}-${index2}`"
            class="mr-8 mb-8 inline-block last-of-type:mr-0"
            @click="selectedVariantGroup = value.code"
          >
            <button
              class="btn btn--secondary"
              :class="[{
                'selected': selectedVariantGroup === value.code,
              }]"
            >
              {{ value.uom ? value.name + ' ' + value.uom : value.name }}
            </button>
          </div>
        </div>
        <!-- category selector mobile -->
        <div v-else class="mb-12">
          <Swiper
            class="swiper-cover w-full"
            :space-between="8"
            :slides-per-view="'auto'"

            :slides-offset-before="12"
            :slides-offset-after="12"
          >
            <SwiperSlide class="!w-fit-content">
              <button
                class="btn btn--secondary inline-block"
                selectedVariantGroup
                :class="[{
                  'selected': !selectedVariantGroup,
                }]"
                @click="selectedVariantGroup = ''"
              >
                {{ $t('productPage.extended.showAll') }}
              </button>
            </SwiperSlide>
            <SwiperSlide
              v-for="(value, index3) in parametric.values"
              :key="`vpvMobile-${value.code}-${index3}`"
              class="!w-fit-content"
            >
              <button
                class="btn btn--secondary inline-block"
                :class="[{
                  'selected': selectedVariantGroup === value.code,
                }]"
                @click="selectedVariantGroup = value.code"
              >
                {{ value.uom ? value.name + ' ' + value.uom : value.name }}
              </button>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div class="mobOnly:px-12">
        <p class="font-medium text-14 mb-12">
          {{
            $t(variants.length === 1 ? 'productPage.extended.shade' : 'productPage.extended.shades', { num: variants.length })
          }}
        </p>
        <div class="border-b border-light pb-16">
          <div
            v-for="(product, index) in variants"
            :key="product.partNo"
          >
            <ExtendedLayoutProductCard
              v-if="index < showVariantsCount"
              :product="product"
            />

            <button
              v-if="(index) === showVariantsCount"
              class="btn w-full my-12"
              @click="showVariantsCount = showVariantsCount + defaultShowVariantsCount"
            >
              {{ $t('filter.loadmore') }}
            </button>
          </div>
        </div>
      </div>

      <client-only>
        <!-- * * * * * * relatedProductsLists -->
        <div class="mobOnly:px-12">
          <div v-if="relatedLoading" class="text-center mt-12">
            <LoadingIcon />
          </div>

          <div
            v-for="(list, index) in relatedProductsLists"
            :key="'extended-related-products-' + index"
            class="border-b border-light last:border-0"
          >
            <div
              class="font-medium uppercase select-none cursor-pointer flex justify-between md:py-24 py-16"
              @click="openItem(list.type)"
            >
              {{ list.title }}
              <img
                src="/admin/chevron-down.svg"
                class="mr-12 text-18 transition-all w-[28px] h-24"
                :class="{
                  'rotate-180': openAccordionItems.includes(list.type),
                }"
                alt="open"
              >
            </div>
            <div
              class="overflow-hidden transition-all duration-500"
              :class="{
                'max-h-0 opacity-0': !openAccordionItems.includes(list.type),
                'max-h-[3000px] opacity-100 md:mb-24 mb-16': openAccordionItems.includes(list.type)
              }"
            >
              <div v-for="(product, indexP) in list.products" :key="'variant-' + indexP">
                <ExtendedLayoutProductCard
                  v-if="indexP < showRelatedCount"
                  :product="product.variants[0]"
                />
                <button
                  v-if="(indexP) === showRelatedCount"
                  class="btn w-full my-12"
                  @click="showRelatedCount = showRelatedCount + defaultShowVariantsCount "
                >
                  {{ $t('filter.loadmore') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ProductImage from '~/components/product-page/ProductImage.vue';
import NameAndCategory from '~/components/product-page/NameAndCategory.vue';
import ExtendedLayoutProductCard from '~/components/product-page/ExtendedLayoutProductCard.vue';
import { NorceMediaSetCodes, NorceRelatedProductTypeCodes } from '~/constants/norceCodes';
import Product, { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';

import { useUiStore } from '~/store/ui';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';

const uiStore = useUiStore();

const props = defineProps<{
  productItem: ProductModel,
  activeVariant: ProductVariantModel,
  currentImageList: NorceMediaSetCodes,
}>();

type RelatedProductList = {
  type: string,
  title: string,
  products: ProductModel[]
}[]

const loadRelations = [NorceRelatedProductTypeCodes.ColorHydrogen, NorceRelatedProductTypeCodes.ColorMaterial];
const productVariantSearchInput = ref<string>('');
const openAccordionItems = ref<string[]>(loadRelations);
const selectedVariantGroup = ref<string>('');
const relatedProductsLists = ref([] as RelatedProductList);
const defaultShowVariantsCount = 10;
const showVariantsCount = ref<number>(defaultShowVariantsCount);
const showRelatedCount = ref<number>(defaultShowVariantsCount);

const { $t } = useNuxtApp();

const variants = computed(() => {
  let filtered = props.productItem.variants;

  if (selectedVariantGroup.value !== '') {
    filtered = filtered
      .filter(v => v.definingParametrics.find(p => p.code === props.productItem.allVariantDefiningParametrics[0].code)?.listValue?.code === selectedVariantGroup.value)
      .filter(v => !v.productHasExpired);
  }

  if (productVariantSearchInput.value !== '') {
    filtered = filtered.filter(v => v.name?.toLocaleLowerCase().includes(productVariantSearchInput.value.toLocaleLowerCase()) || v.partNo.includes(productVariantSearchInput.value));
  }
  return filtered.sort((a, b) => a.sort > b.sort ? 1:-1);
});

const openItem = (id: string) => {
  if (openAccordionItems.value.includes(id)) {
    openAccordionItems.value = openAccordionItems.value.filter(item => item !== id);
  } else {
    openAccordionItems.value = [...openAccordionItems.value, id];
  }
};

const { getExactVariantByPartNo, lastError, lastErrorCode } = useAlgoliaFetch();
const relatedLoading = ref(true);
onMounted(()=> {
  setTimeout(()=> {
    loadRelated();
  }, 200);
});

const loadRelated = async() => {
  const relatedProducts = loadRelations.map((relation) => {
    return {
      relation,
      partNos: props.productItem.relatedProductsByType(relation) || [],
      title: $t('productPage.relatedProducts.' + relation.replace('-', '')),
    };
  });
  const toLoad: string[] = relatedProducts
    .flatMap((f)=> f.partNos)
    .filter((v,i,a) => a.indexOf(v) === i); // remove duplicates;

  const res = await getExactVariantByPartNo(toLoad);

  if (res) {
    relatedProductsLists.value = relatedProducts.map((rm)=> {
      return {
        type: rm.relation,
        title: rm.title,
        products: rm.partNos.map((partNo) => {
          const product = res.hits.findIndex((fi) => fi.partNo === partNo);
          return Product.create(res.hits[product]);
        }),
      };
    });

  } else {
    // fail quietly
    console.error(lastError.value?.message);
    console.log(lastError.value);
    console.log(lastErrorCode.value);
  }

  relatedLoading.value = false;
};

watch(selectedVariantGroup, () => {
  showVariantsCount.value = defaultShowVariantsCount;
});

</script>

<style scoped lang="postcss">
</style>
